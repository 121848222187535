html,
body {
  margin: 0;
  overflow: hidden;
  height: 100%;
  background-image: url("1000gecstree.jpeg");
  background-size: contain;
  background-position: center center;
}

.visually-hidden {
  display: none;
}

#gl {
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  position: absolute;
  padding: 0;
  z-index: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  #gl {
    visibility: hidden;
  }
}

canvas {
  display: block;
}

.logo {
  position: absolute;
  top: 10vh;
  max-width: 800px;
  left: calc(50% - 1em);
  transform: translateX(-50%);
  z-index: 10;
  pointer-events: none;
  opacity: 1;
  mix-blend-mode: exclusion;
  filter: invert(1);
  margin: 1em;
}
.logomin {
  position: absolute;
  bottom: 1em;
  z-index: 500;
}
.logomin.l {
  left: 1em;
}
.logomin.r {
  right: 1em;
}
.logomin img {
  width: 2em;
}